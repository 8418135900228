#login {
  background: #111120;
  height: 100vh;
  position: relative;
  background-image: url(/assets/paper.png);
  background-size: 150px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.canvas-wrapper {
  width: 100%;
  position: absolute;
  top: 0;
}

#login canvas {
  width: calc(100% - 100px);
  height: calc(100vh - 100px);
  margin-top: 50px;
  opacity: 0.5;
}

.switch {
  position: relative;
  width: 200px;
  margin: 0 auto 20px auto;
  text-align: center;
  background: #00000020;
  border-radius: 5px;
}

.switch .slider {
  width: 100px;
  height: 100%;
  position: absolute;
  z-index: 0;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.switch label {
  position: relative;
  padding: 12px 10px 8px 10px;
  display: inline-block;
  border-radius: 5px;
  width: 80px;
  z-index: 1;
  cursor: pointer;
  opacity: 0.5;
}

.switch label.active {
  opacity: 1;
}

#login-modal {
  position: relative;
  padding: 50px;
  background: #111120;
  background-image: url(/assets/paper.png);
  background-size: 150px;
  z-index: 1;
  color: #fff;
  width: 400px;
  text-align: left;
  transition: all 0.5s ease;
}

#login-modal.fade-out {
  transform: translateY(-50px);
  opacity: 0;
}

#login-modal h1 {
  font-size: 5em;
  letter-spacing: -4px;
  margin: 0;
  height: 0;
  color: #ff6262;
}

h1.h1--title {
  transform: translate(-56px, -113px);
}

#login-modal h1 {
  transition: all 0.3s ease;
}

#login-modal input,
#login-modal button {
  color: #ff6262;
  background: transparent;
  width: calc(100% - 30px);
  padding: 15px 15px 12px 15px;
}

#login-modal .input-box,
#login-modal button {
  background-color: #00000020;
  border-color: transparent;
  border-radius: 10px;
}

.input-box + .input-box {
  margin-top: 10px;
}

#login-modal .input-box label {
  margin-top: 15px;
  margin-left: 15px;
  display: block;
}

#login-modal .password input:not(:invalid) {
  font-size: 1.9em;
  padding: 8px 15px 4px;
}

#login-modal input {
  transition: all 0s ease;
}

#login-modal.register input,
#login-modal.register h1 {
  color: #eb4f7d;
}


#login-modal ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ffffff80;
}

#login-modal .h1--button {
  text-align: right;
  margin-top: 25px;
  font-size: 2em;
  letter-spacing: -2px;
  height: 20px;
  box-shadow: 0px 0px 20px 10px #31323fa0;
  background: #31323fa0;
  cursor: pointer;
}

#login-modal .error {
  position: absolute;
  bottom: 40px;
  width: calc(100% - 240px);
  font-size: 0.8em;
  line-height: 1.3;
  color: #ff6767;
}

.login input:-webkit-autofill,
.login input:-webkit-autofill:hover,
.login input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #2b2c37 inset;
  -webkit-text-fill-color: #ff6262;
}

.register input:-webkit-autofill,
.register input:-webkit-autofill:hover,
.register input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #2b2c37 inset;
  -webkit-text-fill-color: #eb4f7d;
}
