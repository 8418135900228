.spreadsheet-dashboard {
  background: #f4f7fa;
  height: calc(100% - 74px);
  padding: 10px 10px;

  position: absolute;
  top: 54px;
  width: calc(100% - 20px);
  border-top: 1px solid var(--cell-border);

  opacity: 0;
  pointer-events: none;
  -webkit-filter: blur(20px);
  transition: all 0.5s ease;
  z-index: 2;
}

.spreadsheet-dashboard.show {
  -webkit-filter: blur(0);
  opacity: 1;
  pointer-events: all;
}

.widget--wrapper {
  position: relative;
  display: inline-block;
  vertical-align: top;
}

.widget--wrapper label {
  display: block;
  margin-left: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  opacity: 0.7;
}

.widget {
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  margin: 10px 10px 0px 10px;

  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}

.widget--wrapper {
  padding: 10px 0px;
}

.widget--snippet .table--read-only:hover {
  transition: all 0.3s ease;
}

.widget:hover,
.widget--snippet .table--read-only:hover {
  box-shadow: 0px 10px 20px -10px #1e365240;
}

.widget--snippet .table--read-only {
  margin: 10px;
  margin-top: 10px;
}

.widget--snippet label .preview-only {
  border: 1px solid rgba(209, 117, 114, 0.25);
  padding: 6px 5px 3px 5px;
  border-radius: 4px;
  color: rgb(209, 117, 114);
  text-transform: uppercase;
  font-size: 0.8em;
  letter-spacing: 0.5px;
  display: inline-block;
  margin-left: 5px;
}

.spreadsheet-dashboard .flex-column {
  flex-direction: column;
}

.widget--equal-space {
  justify-content: space-between;
}

.widget--key-value > div > * {
  text-align: center;
}

.widget--key-value .key {
  text-transform: uppercase;
  letter-spacing: 0.5px;
  opacity: 0.5;
  margin-top: 10px;
}

.widget--key-value .value {
  font-size: 3em;
  font-weight: 300;
}

.widget--snippet {
  display: block;
}

.widget--wrapper.size--1-1 {
  width: calc(100% / 6);
}

.widget--wrapper.size--2-1 {
  width: calc(100% / 3);
}

.widget--wrapper.size--3-1 {
  width: calc(100% / 2);
}

.widget--wrapper.size--4-1 {
  width: calc(100% / (6 / 4));
}

.widget--wrapper .header:hover > .button {
  transform: translateY(4px);
  opacity: 0.7;
  pointer-events: all;
}

.widget--wrapper .header > .button {
  position: absolute;
  top: 0px;
  right: 10px;
  color: rgb(209, 117, 114);
  border-color: rgba(209, 117, 114, 0.25);
  background: transparent;
  box-shadow: -10px 0px 10px 5px #f3f7fa;
  background: #f3f7fa;
  opacity: 0;
  pointer-events: none;
}

.widget > div:last-of-type {
  margin-right: 0px;
}

.widget--snippet .header > .button {
  top: 5px;
}

.widget--snippet .header:hover > .button {
  transform: translateY(4px);
}

.widget--cell {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;

  padding: 0px;
  width: 85px;
}

.widget--cell .star {
  width: 17px;
  height: 17px;
  margin: -5px -1px 0 -1px;
  color: #ffcf57;
  text-shadow: 0px 0px 3px black;
}

.widget--cell .star--empty {
	opacity: 0.5;
}

.add-widget {
  position: absolute;
  bottom: 100px;
  right: 20px;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 10px 40px -10px #1e365280;
  width: 250px;

  transform: scale(0.8) translate(30px, 40px);
  opacity: 0;

  transition: all 0.3s ease;
}

.add-widget.show {
  opacity: 1;
  transform: scale(1) translate(0, 0);
}

.add-widget h3 {
  margin: 0 0 20px 0;
  outline: none;
}

.add-widget > *:last-of-type {
  margin-bottom: 0px;
}

.add-widget .select-configure {
  bottom: calc(100% + 15px);
  transform: translateX(-50%);
  left: 50%;
}

.add-widget *[data-placeholder]:empty::before {
	content: attr(data-placeholder);
	opacity: 0.5;
}

.add-widget .pseudo-focus::after {
  content: none;
}

.add-widget label {
  display: block;
}

input {
  width: calc(100% - 20px);
  padding: 12px 10px 10px 10px;
  border: none;
}

input.mono {
  font-family: menlo;
  font-size: 0.8em;
  white-space: pre-wrap;
  color: #1783bf;
}

input.half {
  width: calc(50% - 20px);
}

.input-box {
  position: relative;
  border: 1px solid var(--cell-border);
  border-radius: 5px;
  margin: 5px 0px;
}

.input-row > div:first-of-type {
  margin-left: 0px;
}

.input-box.half {
  display: inline-block;
  width: calc(50% - 7px);
  margin-left: 10px;
}

.input-box label {
  position: relative;
  font-size: 0.9em;
  font-weight: 600;
  margin-left: 10px;
  margin-top: 10px;
  z-index: 1;
}

.input-box input {
  margin-top: -3px;
}

.add-widget label.label--main {
  margin-top: 15px;
  text-transform: uppercase;
  opacity: 0.5;
  letter-spacing: 0.3px;
  font-size: 0.9em;
}

.add-widget button {
  padding-left: 20px;
  padding-right: 20px;
  float: right;
  margin-top: 20px;
  padding: 13px 20px 9px 20px;
}

.add-widget button.button--blue:hover,
.add-widget button.button--blue:focus {
  color: rgb(38, 145, 190);
  border-color: rgba(38, 145, 190, 0.25);
  background: rgba(38, 145, 190, 0.02);
}

.spreadsheet-dashboard .button--add-button {
  width: 55px;
}

.spreadsheet-dashboard .button--add-button.rotate {
  box-shadow: 4px 2px 20px -10px #1e3652f0;
  transform: rotate(45deg) scale(1.05);
  color: rgb(209, 117, 114);
}
